import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';

import { Controls } from 'components/Controls';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TourFormType } from './TourForm.type';
import { TourHotelInfo, TripInfo } from 'pages/Tours/Tours.types';
import { CalendarIcon, PlusIcon, RemoveIcon } from 'components/icons';
import { DescriptionInfoTab } from './DescriptionInfoTab';
import {
  resetPreSaveTour,
  setIsEditedTour,
  setIsClonedTrip,
  setCurrentTourTripsInfo,
  setCurrentTourHotelInfo,
} from 'store/entities/tours/toursSlice';
import { RouteNames } from 'core/constants/routeNames';
import { TourParametersTab } from './TourParametersTab';
import { TripsTab } from './TripsTab';
import { addDaysToDate, getDate } from 'core/utils/getDate';
import Settings from 'components/Settings';
import { DeleteTripData } from 'services/tours/toursApi.service';
import {
  deleteHotel,
  deleteTrip,
  editTour,
  getDatesDelete,
  getHotelsDelete,
  getTourDate,
  getTourHotel,
} from 'store/action-creators/tours';
import {
  resetPreSaveTour2,
  setPreSaveToursHotelInfo,
  setPreSaveTripsInfo,
  setTourAlbum,
  setTourPhotos,
  setTourPhotosOrder,
} from 'store/entities/tours/preSaveTourSlice';
import { ModalWarning } from './ModalWarning';

import styles from './TourForm.module.scss';
import { ToursTemplates } from 'core/constants/enums';
import { ToursHotelTab } from './ToursHotelTab';
import { GalleryTab } from './GalleryTab';
import { Button } from 'ui/Button';
import { getCurrencyToServer } from 'core/utils/getCurrency';
import {
  tourDatesFactoryOut,
  tourDiscountsFactoryOut,
  tourHotelFactoryOut,
  tourProgramFactoryOut,
  tourServicesFactoryOut,
} from 'services/tours/tours.factory';
import { Checkbox } from 'ui/Checkbox';

export const TourForm: FC<TourFormType> = ({ tour, buttonSend, template }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const addTourUrl = useLocation();
  const { isSuccess, isError, isEditedTour, tags } = useAppSelector((state) => state.tours);
  const { preSaveTour2 } = useAppSelector((state) => state.preSaveTour);
  const tabDataForCreateTour = [
    { value: 'tourDescription', label: 'Описание тура' },
    { value: 'tourParameters', label: 'Параметры' },
    { value: 'tourGallery', label: 'Фотогалерея' },
    template === ToursTemplates.EVERYDAY
      ? { value: 'tourHotels', label: 'Гостиница', addBtn: true }
      : { value: 'tourDates', label: 'Даты тура', addBtn: true },
  ];
  const [tab, setTab] = useState<string>(tabDataForCreateTour[0].value);
  const [viewTrip, setViewTrip] = useState<TripInfo | null>(null);
  const [viewHotel, setViewHotel] = useState<TourHotelInfo | null>(null);
  const [trips, setTrips] = useState<TripInfo[]>([]);
  const [hotelsTrips, setHotelsTrips] = useState<TourHotelInfo[]>([]);
  const [copyTrip, setCopyTrip] = useState<boolean>(false);
  const [opened, { open, close }] = useDisclosure(false);
  const idTour = id?.split('-')[0];
  const idTrip = id?.split('-')[1];
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  // в режиме редактирования открываем тур на вкладке с поездками, на конкретной поездке
  useEffect(() => {
    if (idTrip && trips.length > 0 && template != ToursTemplates.EVERYDAY) {
      setViewTrip(trips.filter((trip) => trip.id == idTrip)[0]);
      setTab('trip');
    } else if (idTrip && hotelsTrips.length > 0 && template === ToursTemplates.EVERYDAY) {
      setViewHotel(hotelsTrips.filter((trip) => trip.id == idTrip)[0]);
      setTab('hotel');
    }
  }, [idTrip, trips, hotelsTrips, viewTrip]);

  useEffect(() => {
    if (!idTrip || viewTrip?.hotels || template === ToursTemplates.EVERYDAY) {
      return;
    }
    dispatch(getTourDate({ id: String(idTrip) }));
  }, [viewTrip, idTrip]);

  useEffect(() => {
    if (!idTrip || viewHotel?.tour_hotel_placements || template !== ToursTemplates.EVERYDAY) {
      return;
    }
    dispatch(getTourHotel({ id: String(idTrip) }));
  }, [viewHotel, idTrip]);

  // создание тура, отравка запроса на сервер и выход на список туров
  const onSubmit = () => {
    if (buttonSend) {
      buttonSend();
    }
  };
  const onEdit = () => {
    const tourData = {
      //сбор данных для запроса из вкладки "описание тура"
      id: Number(preSaveTour2.id),
      data: {
        name: preSaveTour2.name,
        template: preSaveTour2.template,
        is_visible: preSaveTour2.is_visible,
        currency: getCurrencyToServer(preSaveTour2.descriptionInfo.currency!),
        commission: preSaveTour2.descriptionInfo.commission,
        duration: template === ToursTemplates.ONEDAY ? 0 : Number(preSaveTour2.descriptionInfo?.duration),
        tour_operator_contact:
          preSaveTour2.descriptionInfo?.tourOperator?.name != 'Акварель'
            ? `/api/v1/contacts/${preSaveTour2.descriptionInfo?.tourOperator?.id}`
            : null,
        sale:
          preSaveTour2.descriptionInfo?.discount === 0 || preSaveTour2.descriptionInfo?.discount === ''
            ? '0'
            : preSaveTour2.descriptionInfo?.discount?.toString(),
        sale_percent:
          preSaveTour2.descriptionInfo?.discount_percent === 0 || preSaveTour2.descriptionInfo?.discount_percent === ''
            ? '0'
            : preSaveTour2.descriptionInfo?.discount_percent?.toString(),
        short_description: preSaveTour2.descriptionInfo?.shortDescription,
        description: preSaveTour2.descriptionInfo?.fullDescription,
        additional_info: preSaveTour2.descriptionInfo.addInfo,
        services: preSaveTour2.descriptionInfo?.priceIncludes,
        not_in_price: preSaveTour2.descriptionInfo.priceNoIncludes,
        system_name: preSaveTour2.descriptionInfo.system_name,
        partners_site: preSaveTour2.descriptionInfo.partners_site,
        document_template_path: preSaveTour2.descriptionInfo.document_template_path,
        tour_days: preSaveTour2.descriptionInfo?.tourProgram?.map((day) => tourProgramFactoryOut(day)),
        meta_title: preSaveTour2.descriptionInfo.meta_tags.title,
        meta_description: preSaveTour2.descriptionInfo.meta_tags.description,
        services_array: preSaveTour2.descriptionInfo?.additionalServices
          ?.filter((i) => i)
          .map((service) => tourServicesFactoryOut(service)),
        sales_array: preSaveTour2.descriptionInfo?.additionalDiscounts?.map((discount) =>
          tourDiscountsFactoryOut(discount)
        ),
        price:
          preSaveTour2.descriptionInfo?.price?.toString() !== ''
            ? preSaveTour2.descriptionInfo?.price?.toString()
            : '0',
        booking_without_prepay: preSaveTour2.descriptionInfo.booking_without_prepay,
        filter_price: preSaveTour2.descriptionInfo.filter_price,
        monday: preSaveTour2.descriptionInfo.monday,
        tuesday: preSaveTour2.descriptionInfo.tuesday,
        wednesday: preSaveTour2.descriptionInfo.wednesday,
        thursday: preSaveTour2.descriptionInfo.thursday,
        friday: preSaveTour2.descriptionInfo.friday,
        saturday: preSaveTour2.descriptionInfo.saturday,
        sunday: preSaveTour2.descriptionInfo.sunday,
        monday_title: preSaveTour2.descriptionInfo.monday_title,
        tuesday_title: preSaveTour2.descriptionInfo.tuesday_title,
        wednesday_title: preSaveTour2.descriptionInfo.wednesday_title,
        thursday_title: preSaveTour2.descriptionInfo.thursday_title,
        friday_title: preSaveTour2.descriptionInfo.friday_title,
        saturday_title: preSaveTour2.descriptionInfo.saturday_title,
        sunday_title: preSaveTour2.descriptionInfo.sunday_title,
        eightday: preSaveTour2.descriptionInfo.eightday,
        eightday_title: preSaveTour2.descriptionInfo.eightday_title,
        //сбор данных для запроса из вкладки "параметры"
        managers: preSaveTour2.tourParameters?.tourManager?.map((manager) => manager['@id']),
        guides: preSaveTour2.tourParameters?.guide?.map((direction) => {
          return direction['@id']!;
        }),
        countries: preSaveTour2.tourParameters?.country?.map((direction) => {
          return direction['@id']!;
        }),
        cities: preSaveTour2.tourParameters?.city?.map((direction) => {
          return direction['@id']!;
        }),
        transports: preSaveTour2.tourParameters?.transport?.map((direction) => {
          return direction['@id']!;
        }),
        labels: preSaveTour2.tourParameters?.tourType?.map((direction) => {
          return direction['@id']!;
        }),
        tour_periods: preSaveTour2.tourParameters?.travelTime?.map((direction) => {
          return direction['@id']!;
        }),
        tour_directions: preSaveTour2.tourParameters?.direction?.map((direction) => {
          return direction['@id']!;
        }),
        tour_tags: preSaveTour2.tourParameters?.tags?.map((direction) => {
          return direction['@id']!;
        }),
        priority: preSaveTour2.descriptionInfo.priority ? preSaveTour2.descriptionInfo.priority : 1,
        main_tag:
          tags.length > 0
            ? preSaveTour2.tourParameters.main_tag
              ? preSaveTour2.tourParameters?.tags?.find((el) => el.name === preSaveTour2.tourParameters.main_tag)?.[
                  '@id'
                ]
              : null
            : undefined,
        //сбор данных для запроса из вкладки "даты тура"
        tour_dates: preSaveTour2.tripsInfo?.map((trip) =>
          tourDatesFactoryOut(trip, preSaveTour2.descriptionInfo.duration)
        ),
        tour_hotels: preSaveTour2.tourHotels?.map((el) => tourHotelFactoryOut(el)),
        photos: preSaveTour2.photos?.map((el) => `/api/v1/photos/${el.id}`),
        photo_album: preSaveTour2.photo_album ? `/api/v1/photo-albums/${preSaveTour2.photo_album.id}` : null,
        tour_group_title: preSaveTour2.descriptionInfo.tour_group_title,
        tour_group_placements: preSaveTour2.descriptionInfo?.tour_group_placements?.map((el) => {
          if (el.id) {
            return {
              id: `/api/v1/tour-group-placements/${el.id}`,
              hotel_string: el.hotel_string,
              description: el.description,
              price1: el.price1 === '' ? null : el.price1,
              price2: el.price2 === '' ? null : el.price2,
              price3: el.price3 === '' ? null : el.price3,
              price4: el.price4 === '' ? null : el.price4,
              price5: el.price5 === '' ? null : el.price5,
            };
          } else {
            return {
              hotel_string: el.hotel_string,
              description: el.description,
              price1: el.price1 === '' ? null : el.price1,
              price2: el.price2 === '' ? null : el.price2,
              price3: el.price3 === '' ? null : el.price3,
              price4: el.price4 === '' ? null : el.price4,
              price5: el.price5 === '' ? null : el.price5,
            };
          }
        }),
        settings: {
          tourGroup: preSaveTour2.descriptionInfo.settings?.tourGroup,
          photoOrders: preSaveTour2.descriptionInfo.settings?.photoOrders,
        },
      },
    };
    if (tourData && tourData.id != 0) {
      dispatch(editTour(tourData));
      dispatch(setIsEditedTour(false));
      dispatch(setIsClonedTrip(false));
    }
  };
  // вызываем модалку для подтверждения
  const handleCancel = () => {
    open();
  };

  // клик на отдельной поездке
  const handleTripClick = (trip?: TripInfo) => {
    if (trip) {
      dispatch(getTourDate({ id: String(trip.id) }));
      setViewTrip(trip);
      navigate(`${RouteNames.TOURS}/${idTour}-${trip.id?.toString()}`);
    }
    setTab('trip');
  };

  // клик на отдельной поездке
  const handleHotelClick = (trip?: TourHotelInfo) => {
    if (trip) {
      dispatch(getTourHotel({ id: String(trip.id) }));
      setViewHotel(trip);
      navigate(`${RouteNames.TOURS}/${idTour}-${trip.id?.toString()}`);
    }
    setTab('hotel');
  };

  //нажатие на кнопку "плюсик" - открывается форма для добавления новой поездки,
  //также осуществляется переход на вкладку с отдельными поездками
  // впоследствии пришла задача сделать "даты тура" с таким же функционалом, так что наверное отдельную обработку плюса можно убрать
  const handleNewTripClick = () => {
    if (addTourUrl.pathname === '/tours/new') {
      notifications.show({ title: 'Ошибка', color: 'red', message: 'Перед добавлением поездки нужно создать тур' });
    } else {
      if (template === ToursTemplates.EVERYDAY) {
        setViewHotel(null);
        setTab('tourHotels');
      } else {
        setViewTrip(null);
        setTab('tourDates');
      }
    }
  };

  // удаление поездки
  const handleSettingsDelete = (id: number | string | undefined | null) => {
    const updatePreSaveTrips = preSaveTour2.tripsInfo?.filter((item) => item.id !== id);

    const idFactory = (id: number | string): DeleteTripData => {
      return {
        id: id,
      };
    };
    if (id) {
      dispatch(deleteTrip(idFactory(id)));
      dispatch(setPreSaveTripsInfo(updatePreSaveTrips));
    }
  };

  const handleSettingsHotelDelete = (id: number | string | undefined | null) => {
    const updatePreSaveTrips = preSaveTour2.tourHotels?.filter((item) => item.id !== id);

    const idFactory = (id: number | string): DeleteTripData => {
      return {
        id: id,
      };
    };
    if (id) {
      dispatch(deleteHotel(idFactory(id)));
      dispatch(setPreSaveToursHotelInfo(updatePreSaveTrips));
    }
  };
  // дублирование поездки
  const handleSettingsHotelClone = (id: string | null | undefined) => {
    const tripWithID = preSaveTour2.tourHotels?.find((item) => item.id === id);
    if (tripWithID) {
      const newTrip = {
        '@id': undefined,
        name: tripWithID.name,
        distance: tripWithID.distance,
        address: tripWithID.address,
        sale_percent: tripWithID.sale_percent?.toString(),
        sale: tripWithID.sale?.toString(),
        tour_hotel_placements: tripWithID?.tour_hotel_placements?.map((hotel) => ({ ...hotel, '@id': undefined })),
      };
      setCopyTrip(true);
      dispatch(setPreSaveToursHotelInfo([...(preSaveTour2.tourHotels || []), newTrip]));
    }
  };

  // дублирование поездки
  const handleSettingsClone = (id: string | null | undefined) => {
    const tripWithID = preSaveTour2.tripsInfo?.find((item) => item.id === id);
    if (tripWithID) {
      const newTrip = {
        '@id': undefined,
        dateStart: tripWithID.dateStart,
        dateEnd: tripWithID.dateEnd,
        priceOneDay: tripWithID.priceOneDay,
        freePlaces: tripWithID.freePlaces,
        discount_percent: tripWithID.discount_percent,
        discount: tripWithID.discount,
        status: tripWithID.status,
        hotels: tripWithID.hotels?.map((hotel) => ({ ...hotel, '@id': undefined })),
        tour_additional_nights: tripWithID.tour_additional_nights?.map((hotel) => ({ ...hotel, '@id': undefined })),
      };
      setCopyTrip(true);
      dispatch(setPreSaveTripsInfo([...(preSaveTour2.tripsInfo || []), newTrip]));
    }
  };

  // если продублировали поездку, то ставим флаг об изменении тура
  // после этого отправится запрос на сервер. если в ответе придет измененный массив поездок (кол-во),
  // то перезагрузится страница, чтобы подтянуть id для новой поездки
  useEffect(() => {
    if (copyTrip) {
      setIsEditedTour(true);
      dispatch(setIsClonedTrip(true));
      setCopyTrip(false);
    }
  }, [copyTrip]);

  // когда меняется массив поездок в текущем туре, обновляем его в таблице.
  useEffect(() => {
    if (tour?.tripsInfo) {
      setTrips(tour?.tripsInfo);
    }
  }, [tour?.tripsInfo]);

  useEffect(() => {
    if (tour?.tourHotels) {
      setHotelsTrips(tour?.tourHotels);
    }
  }, [tour?.tourHotels]);

  //если изменилась длительность тура, то проходимся по всем поездкам и высчитываем дату окончания.
  useEffect(() => {
    const updatePreSaveTrips = preSaveTour2?.tripsInfo?.map((trip) => {
      if (trip.dateStart && preSaveTour2.descriptionInfo.duration) {
        trip = { ...trip, dateEnd: addDaysToDate(trip.dateStart, preSaveTour2.descriptionInfo.duration) };
      }
      return trip;
    });
    if (updatePreSaveTrips && isEditedTour) {
      dispatch(setPreSaveTripsInfo(updatePreSaveTrips));
    }
  }, [preSaveTour2.descriptionInfo.duration]);

  // нотификация
  useEffect(() => {
    if (isSuccess === 'DELETE_TRIP') {
      notifications.show({ title: 'Успешно', color: 'green', message: 'Поездка удалена', id: 'TRIP_DELETE' });
      // переходим на основную страницу тура, обновляем данные
      navigate(`${RouteNames.TOURS}/${idTour}`);
      location.reload();
    }
    if (isError === 'DELETE_TRIP') {
      notifications.show({
        title: 'Ошибка',
        color: 'red',
        message: 'Ошибка отправки данных на сервер',
        id: 'TRIP_DELETE_ERROR',
      });
    }
  }, [isSuccess, isError]);

  // обработка кликов по табам сайдбара
  const clickOnTab = (tab: string) => {
    // в режиме редактирования при кликах на описание и параметры, в url адрес тура, а не поездки
    if (tour !== undefined) {
      if (tab === 'tourDescription' || tab === 'tourParameters') {
        navigate(`${RouteNames.TOURS}/${idTour}`);
        setViewTrip(null);
        setViewHotel(null);
      }
    }

    // при попытке перейти на добавление поездок когда тур еще не создан - выдаем ошибку.
    // для всех остальных случаев устанавливаем нужный таб
    if (tab === 'tourDates' || tab === 'tourHotels' || tab === 'tourGallery') {
      if (addTourUrl.pathname === '/tours/new') {
        notifications.show({
          title: 'Ошибка',
          color: 'red',
          message:
            tab === 'tourGallery'
              ? 'Перед добавлением фотогалереи нужно создать тур'
              : 'Перед добавлением поездки нужно создать тур',
        });
      } else {
        setTab(tab);
        setViewTrip(null);
        setViewHotel(null);
        navigate(`${RouteNames.TOURS}/${idTour}`);
      }
    } else {
      setTab(tab);
    }
  };

  // модалка по подтверждению действия
  const handleCloseModal = () => {
    dispatch(resetPreSaveTour());
    dispatch(resetPreSaveTour2());
    navigate(`${RouteNames.TOURS}`);
  };

  const handleCancelModal = () => {
    close();
  };

  return (
    <div className={styles.form}>
      <div className={styles.navbar}>
        {tabDataForCreateTour.slice(0, template != ToursTemplates.NO_DATE ? 4 : 3).map((item) => (
          <div
            key={item.value}
            onClick={() => clickOnTab(item.value)}
            className={clsx(
              styles.navbarItem,
              tab === item.value && styles.navbarItemActive,
              item.value === 'tourDates' && styles.navbarItemDate
            )}
          >
            {item.label}
            {item.addBtn && (
              <button className={styles.navbarBtn} onClick={() => handleNewTripClick()}>
                <PlusIcon width={13} height={13} />
              </button>
            )}
          </div>
        ))}
        {template === ToursTemplates.EVERYDAY && (
          <div className={styles.trips}>
            {hotelsTrips.map((trip) => (
              <div
                className={clsx(styles.trip, trip.id == idTrip && styles.tripActive)}
                key={trip.id ? trip.id : `new ${trip.name}`}
              >
                <span onClick={() => handleHotelClick(trip)}>{trip.name}</span>
                <Checkbox
                  isChecked={selectedIds.includes(Number(trip.id))}
                  setIsChecked={() => {
                    if (selectedIds.includes(Number(trip.id))) {
                      setSelectedIds((prev) => prev.filter((el) => el != Number(trip.id)));
                      return;
                    }
                    setSelectedIds((prev) => [...prev, Number(trip.id)]);
                  }}
                />
                <Settings
                  btnDeleteText={'Удалить'}
                  btnCopyText={'Дублировать'}
                  bntDelete={() => handleSettingsHotelDelete(trip.id)}
                  bntCopy={() => handleSettingsHotelClone(trip.id)}
                  className={styles.editTrip}
                />
              </div>
            ))}
          </div>
        )}
        {template != ToursTemplates.NO_DATE && template != ToursTemplates.EVERYDAY && (
          <div className={styles.trips}>
            {trips.length > 0 ? (
              trips.map((trip) => (
                <div
                  className={clsx(styles.trip, trip.id == idTrip && styles.tripActive)}
                  key={trip.id ? trip.id : `new ${trip.dateStart}`}
                >
                  <span onClick={() => handleTripClick(trip)}>
                    <CalendarIcon width={14} height={14} fill="#C8CDD8" />
                    {trip.dateStart && getDate(trip.dateStart)}
                  </span>
                  <Checkbox
                    isChecked={selectedIds.includes(Number(trip.id))}
                    setIsChecked={() => {
                      if (selectedIds.includes(Number(trip.id))) {
                        setSelectedIds((prev) => prev.filter((el) => el != Number(trip.id)));
                        return;
                      }
                      setSelectedIds((prev) => [...prev, Number(trip.id)]);
                    }}
                  />
                  <Settings
                    btnDeleteText={'Удалить'}
                    btnCopyText={'Дублировать'}
                    bntDelete={() => handleSettingsDelete(trip.id)}
                    bntCopy={() => handleSettingsClone(trip.id)}
                    className={styles.editTrip}
                  />
                </div>
              ))
            ) : (
              <div className={styles.trip} onClick={() => handleNewTripClick()}>
                <CalendarIcon width={14} height={14} fill="#C8CDD8" />
                <span className={styles.placeholderDate}>_ _ . _ _ . _ _ _ _</span>
              </div>
            )}
          </div>
        )}
        {template != ToursTemplates.NO_DATE && template != ToursTemplates.EVERYDAY && tour != undefined && (
          <Button
            transparent={true}
            label="Удалить даты"
            className={styles.deleteBtn}
            onClick={() => {
              if (selectedIds.includes(Number(viewTrip?.id))) {
                setTab('tourDescription');
                navigate(`${RouteNames.TOURS}/${idTour}`);
              }
              setTrips((prev) => prev.filter((el) => !selectedIds.includes(Number(el.id))));
              dispatch(
                setCurrentTourTripsInfo(preSaveTour2.tripsInfo?.filter((el) => !selectedIds.includes(Number(el.id))))
              );
              dispatch(getDatesDelete({ id: selectedIds }));
              dispatch(
                setPreSaveTripsInfo(preSaveTour2.tripsInfo?.filter((el) => !selectedIds.includes(Number(el.id))))
              );
              setSelectedIds([]);
            }}
          >
            <RemoveIcon fill="#70A4CB" width={16} height={16} />
          </Button>
        )}
        {template === ToursTemplates.EVERYDAY && tour != undefined && (
          <Button
            transparent={true}
            label="Удалить гостиницы"
            className={styles.deleteBtn}
            onClick={() => {
              if (selectedIds.includes(Number(viewHotel?.id))) {
                setTab('tourDescription');
                navigate(`${RouteNames.TOURS}/${idTour}`);
              }
              setHotelsTrips((prev) => prev.filter((el) => !selectedIds.includes(Number(el.id))));
              dispatch(
                setCurrentTourHotelInfo(preSaveTour2.tourHotels?.filter((el) => !selectedIds.includes(Number(el.id))))
              );
              dispatch(
                setPreSaveToursHotelInfo(preSaveTour2.tourHotels?.filter((el) => !selectedIds.includes(Number(el.id))))
              );
              dispatch(getHotelsDelete({ id: selectedIds }));
              setSelectedIds([]);
            }}
          >
            <RemoveIcon fill="#70A4CB" width={16} height={16} />
          </Button>
        )}
      </div>
      <div className={styles.content}>
        {tab === 'tourDescription' && (
          <DescriptionInfoTab descriptionInfo={tour?.descriptionInfo} template={template} />
        )}
        {tab === 'tourParameters' && (
          <TourParametersTab tourParametersInfo={tour?.tourParameters} template={template} />
        )}
        {tab === 'tourGallery' && (
          <GalleryTab
            orders={tour?.descriptionInfo?.settings?.photoOrders}
            tourAlbum={tour?.photo_album}
            photos={tour?.photos}
            setPhoto={(a) => {
              dispatch(setTourPhotosOrder(a.map((el) => Number(el.id))));
              dispatch(setTourPhotos(a));
              dispatch(setIsEditedTour(true));
            }}
            setPreSaveAlbum={(a) => {
              dispatch(setTourAlbum(a));
              dispatch(setIsEditedTour(true));
            }}
          />
        )}
        {(tab === 'tourDates' || tab === 'trip') && <TripsTab trip={viewTrip} setCopy={setCopyTrip} />}
        {(tab === 'tourHotels' || tab === 'hotel') && (
          <ToursHotelTab template={template} tripsInfo={tour?.tourHotels} trip={viewHotel} />
        )}
        {tour === undefined && (
          <>
            <ModalWarning
              opened={opened}
              close={close}
              handleClose={handleCloseModal}
              handleCancel={handleCancelModal}
            />
            <Controls
              btnSaveHandler={onSubmit}
              btnCancelHandler={handleCancel}
              btnSaveLabel="Создать"
              btnCancelLabel="Отмена"
            />
          </>
        )}
        {tour != undefined && (tab === 'tourDescription' || tab === 'tourParameters') && (
          <div className={styles.editBlock}>
            <Button onClick={() => onEdit()} className={styles.addTrip}>
              Сохранить изменения
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
