import {
  AllTourShortInfo,
  HotelInfo,
  TourHotelInfo,
  TourProps,
  TourShortInfo,
  TripInfo,
} from 'pages/Tours/Tours.types';
import {
  AllTourShortResponse,
  CityResponse,
  CountryResponse,
  DirectionResponse,
  DiscountResponse,
  GuidResponse,
  ManagerResponse,
  PlacementResponse,
  ServiceResponse,
  TagResponse,
  TourHotelResponse,
  TourParamArrayData,
  TourParams,
  TourProgramResponse,
  TourRealShortResponse,
  TourResponse,
  TourShortResponse,
  TourTypeResponse,
  TransportResponse,
  TravelTimeResponse,
  TripResponse,
} from './ToursResponse.model';
import { TourProgramDay } from 'pages/Tours/components/TourForm/TourProgram/TourProgram.types';
import {
  PlacementDTO,
  ProgramTourDTO,
  TourDatesDTO,
  TourDiscountsDTO,
  TourHotelsDTO,
  TourServicesDTO,
} from './toursApi.service';
import { ServiceProps } from 'components/AddService/AddService.type';
import { TourStatus, TypeTour } from 'core/constants/enums';
import { SelectMultiItemType } from 'ui/SelectMulti/SelectMulti.type';
import { addDaysToDate, getDate, getTimezonelessDate } from '../../core/utils/getDate';
import { getTemplateToUI } from 'core/utils/getTemplate';
import { getCurrencyToUI } from 'core/utils/getCurrency';
import { listOfIdsTypePlacements } from 'core/constants/lists';
import { TableDescColumn } from 'components/AddDescription/AddService.type';
import { SalesProps } from 'components/AddSales/AddSales.type';
import { getConditionFromSales, getSales } from 'core/utils/getSales';

export const toursFactory = (model: TourRealShortResponse): TourShortInfo => {
  return {
    id: model.id,
    title: model.name,
    tourOperator: model.tour_operator_contact != null ? model.tour_operator_contact.full_name : 'Акварель',
    route: model.short_description_formatted,
  };
};

//конвертация поездки - полученный с сервера -> вывод в интерфейс. данные в сокращенном виде, для таблицы
export const tourShortFactoryIn = (model: TourShortResponse): TourShortInfo => {
  return {
    id: model.id,
    idTrip: model.id,
    idTour: model.tour.id,
    title: model.tour.name,
    dateFrom: model.start ? model.start.slice(0, 10) : '',
    dateTo: model.ending ? model.ending.slice(0, 10) : '',
    tourOperator: model.tour.tour_operator_contact != null ? model.tour.tour_operator_contact.full_name : 'Акварель',
    route: model.tour.short_description_formatted,
    system_name: model.tour.system_name,
  };
};

//конвертация тура - полученный с сервера -> вывод в интерфейс. данные в сокращенном виде, для таблицы c турами без поездок
export const allTourShortFactoryIn = (model: AllTourShortResponse): AllTourShortInfo => {
  return {
    id: model.id,
    title: model.name,
    tourOperator: model.tour_operator_contact != null ? model.tour_operator_contact.full_name : 'Акварель',
    route: model.short_description_formatted,
    tourDates: model.tour_dates,
    system_name: model.system_name,
    tour_duration_dates: model.tour_duration_dates,
  };
};

//конвертация программы тура - из интерфейса -> отправка на сервер
export const tourProgramFactoryOut = (model: TourProgramDay): ProgramTourDTO => {
  if (model['@id']) {
    return {
      id: model['@id'],
      description: model.text,
      name: model.name,
    };
  } else {
    return {
      description: model.text,
      name: model.name,
    };
  }
};

//конвертация программы тура - полученный с сервера -> вывод в интерфейс
export const tourProgramFactoryIn = (model: TourProgramResponse): TourProgramDay => {
  return {
    number: model.id,
    '@id': model['@id'],
    text: model.description,
    name: model.name,
  };
};

//конвертация дополнительных сервисов в туре - из интерфейса -> отправка на сервер
export const tourServicesFactoryOut = (model: ServiceProps): TourServicesDTO => {
  return {
    name: model?.service,
    price: Number(model?.price),
    icon: model.icon,
    siteView: model.siteView,
  };
};

//конвертация дополнительных сервисов в туре - полученный с сервера -> вывод в интерфейс
export const tourServicesFactoryIn = (model: ServiceResponse): ServiceProps => {
  return {
    service: model.name,
    price: model.price,
    icon: model.icon,
    siteView: model.siteView,
  };
};
//конвертация дополнительных скидок в туре - из интерфейса -> отправка на сервер
export const tourDiscountsFactoryOut = (model: SalesProps): TourDiscountsDTO => {
  return {
    name: model.title,
    sale_percent: model.price_percent,
    sale_value: model.price,
    ...getSales(model.condition),
  };
};

//конвертация дополнительных скидок в туре - полученный с сервера -> вывод в интерфейс
export const tourDiscountsFactoryIn = (model: DiscountResponse): SalesProps => {
  return {
    title: model.name,
    price: model.sale_value,
    price_percent: model.sale_percent,
    condition: getConditionFromSales(model.years_from, model.years_to, model.sex),
  };
};

//конвертация дополнительных скидок в туре - из интерфейса -> отправка на сервер
// export const tourDiscountsFactoryOut = (model: SalesProps): TourDiscountsDTO => {
//   return {
//     name: model.title,
//     sale_percent: model.price_percent,
//     sale_value: model.price,
//     ...getSales(model.condition),
//   };
// };

// //конвертация дополнительных скидок в туре - полученный с сервера -> вывод в интерфейс
// export const tourDiscountsFactoryIn = (model: DiscountResponse): SalesProps => {
//   return {
//     title: model.name,
//     price: model.sale_value,
//     price_percent: model.sale_percent,
//     condition: getConditionFromSales(model.years_from, model.years_to, model.sex),
//   };
// };

//конвертация менеджеров - полученный с сервера -> вывод в интерфейс
export const tourManagersFactoryIn = (model: ManagerResponse): SelectMultiItemType => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: `${model.last_name} ${model.first_name} ${model.middle_name ? model.middle_name : ''}`,
  };
};

//конвертация параметра - из интерфейса на сервер
export const tourParamFactoryOut = (model: SelectMultiItemType): TourParamArrayData => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: model.name,
  };
};

//конвертация гидов - полученный с сервера -> вывод в интерфейс
export const tourGuidsFactoryIn = (model: GuidResponse): SelectMultiItemType => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: model.name,
  };
};

//конвертация страны - полученный с сервера -> вывод в интерфейс
export const tourCountryFactoryIn = (model: CountryResponse): SelectMultiItemType => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: model.name,
  };
};

//конвертация города - полученный с сервера -> вывод в интерфейс
export const tourCityFactoryIn = (model: CityResponse): SelectMultiItemType => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: model.name,
  };
};

//конвертация транспорта - полученный с сервера -> вывод в интерфейс
export const tourTransportFactoryIn = (model: TransportResponse): SelectMultiItemType => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: model.name,
  };
};

//конвертация типа тура - полученный с сервера -> вывод в интерфейс
export const tourTypeFactoryIn = (model: TourTypeResponse): SelectMultiItemType => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: model.name,
  };
};

//конвертация времени тура - полученный с сервера -> вывод в интерфейс
export const tourTimeFactoryIn = (model: TravelTimeResponse): SelectMultiItemType => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: model.name,
  };
};

//конвертация направления тура - полученный с сервера -> вывод в интерфейс
export const tourDirectionFactoryIn = (model: DirectionResponse): SelectMultiItemType => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: model.name,
  };
};

//конвертация тегов тура - полученный с сервера -> вывод в интерфейс
export const tourTagFactoryIn = (model: TagResponse): SelectMultiItemType => {
  return {
    '@id': model['@id'],
    id: model.id,
    name: model.name,
  };
};

//конвертация списка отелей - из интерфейса -> отправка на сервер
export const tourHotelsFactoryOut = (model: HotelInfo): PlacementDTO => {
  const type = listOfIdsTypePlacements.find((i) => i.value === model.placement_type_string);

  return {
    id: model['@id'] ? model['@id'] : undefined,
    price1: String(model.price1A) || '0',
    price2: String(model.price2A) || '0',
    price3: String(model.price3A) || '0',
    price4: String(model.price1A1C) || '0',
    price5: String(model.price1A2C) || '0',
    price6: String(model.price2A1C) || '0',
    price7: String(model.price2A2C) || '0',
    hotel_string: model.hotel,
    places_count: model.places_count != '' ? Number(model.places_count) : null,
    food_type_string: model.food_type_string,
    show_no_places: model.show_no_places,
    room_type_string: model.typeRoom,
    room_category_string: model.category,
    placement_type_string: type ? String(type.id) : '',
    start: model.period && model.period[0] != null ? getDate(new Date(model.period[0]!)) : undefined,
    ending: model.period && model.period[1] != null ? getDate(new Date(model.period[1]!)) : undefined,
  };
};

//конвертация списка отелей - полученный с сервера -> вывод в интерфейс
export const tourHotelsFactoryIn = (model: PlacementResponse): HotelInfo => {
  const type = listOfIdsTypePlacements.find((i) => i.id.toString() === model.placement_type_string);
  return {
    '@id': model['@id'],
    id: model.id,
    hotel: model.hotel_string,
    typeRoom: String(model.room_type_string),
    category: model.room_category_string,
    food_type_string: model.food_type_string,
    places_count: model.places_count || model.places_count === 0 ? model.places_count.toString() : '',
    placement_type_string: type ? type.value : '',
    period: model.start ? [new Date(model.start), new Date(model.ending)] : undefined,
    show_no_places: model.show_no_places,
    saled_places_count: model.saled_places_count,
    price1A: Number(model.price1).toString(),
    price2A: Number(model.price2).toString(),
    price3A: Number(model.price3).toString(),
    price1A1C: Number(model.price4).toString(),
    price1A2C: Number(model.price5).toString(),
    price2A1C: Number(model.price6).toString(),
    price2A2C: Number(model.price7).toString(),
  };
};

//конвертация списка поездок - из интерфейса -> отправка на сервер
export const tourDatesFactoryOut = (model: TripInfo, duration?: number): TourDatesDTO => {
  let status = '';
  switch (model.status) {
    case TypeTour.ACTIVE_TOUR:
      status = TourStatus.ACTIVE;
      break;
    case TypeTour.CLOSE_TOUR:
      status = TourStatus.ARCHIVE;
      break;
    default:
      status = TourStatus.ACTIVE;
      break;
  }

  if (model['@id']) {
    return {
      id: model['@id'],
      start: model.dateStart ? getTimezonelessDate(model.dateStart) : undefined,
      ending: model.dateStart ? addDaysToDate(model.dateStart, Number(duration)) : undefined,
      base_price: Number(model.priceOneDay) > 0 ? model.priceOneDay?.toString() : undefined,
      vacant_places_count: Number(model.freePlaces) > 0 ? Number(model.freePlaces) : 0,
      sale_percent: model.discount_percent?.toString(),
      sale: model.discount?.toString(),
      agents_sale: model.agents_sale?.toString(),
      agents_sale_percent: model.agents_sale_percent?.toString(),
      status: status,
      placements:
        model.hotels && model.hotels?.length > 0
          ? model.hotels?.map((hotel) => tourHotelsFactoryOut(hotel))
          : undefined,
      tour_additional_nights: model.tour_additional_nights?.map((hotel) => tourHotelsFactoryOut(hotel)),
    };
  } else {
    return {
      start: model.dateStart ? getTimezonelessDate(model.dateStart) : undefined,
      ending: model.dateEnd ? getTimezonelessDate(model.dateEnd) : undefined,
      base_price: Number(model.priceOneDay) > 0 ? model.priceOneDay?.toString() : undefined,
      vacant_places_count: Number(model.freePlaces) > 0 ? Number(model.freePlaces) : 0,
      sale_percent: model.discount_percent?.toString(),
      sale: model.discount?.toString(),
      agents_sale: model.agents_sale?.toString(),
      agents_sale_percent: model.agents_sale_percent?.toString(),
      status: status,
      placements:
        model.hotels && model.hotels?.length > 0
          ? model.hotels?.map((hotel) => tourHotelsFactoryOut(hotel))
          : undefined,
      tour_additional_nights:
        model.tour_additional_nights && model.tour_additional_nights?.length > 0
          ? model.tour_additional_nights?.map((hotel) => tourHotelsFactoryOut(hotel))
          : undefined,
    };
  }
};

export const tourHotelFactoryOut = (model: TourHotelInfo): TourHotelsDTO => {
  if (model['@id']) {
    return {
      id: model['@id'],
      name: model.name,
      distance: model.distance,
      address: model.address,
      sale_percent: Number(model.sale_percent) > 0 ? model.sale_percent?.toString() : null,
      sale: Number(model.sale) > 0 ? model.sale?.toString() : null,
      agents_sale: model.agents_sale?.toString(),
      agents_sale_percent: model.agents_sale_percent?.toString(),
      tour_hotel_placements:
        model.tour_hotel_placements && model.tour_hotel_placements.length > 0
          ? model.tour_hotel_placements?.map((hotel) => tourHotelsFactoryOut(hotel))
          : undefined,
    };
  } else {
    return {
      name: model.name,
      distance: model.distance,
      address: model.address,
      sale_percent: Number(model.sale_percent) > 0 ? model.sale_percent?.toString() : null,
      sale: Number(model.sale) > 0 ? model.sale?.toString() : null,
      agents_sale: model.agents_sale?.toString(),
      agents_sale_percent: model.agents_sale_percent?.toString(),
      tour_hotel_placements:
        model.tour_hotel_placements && model.tour_hotel_placements.length > 0
          ? model.tour_hotel_placements?.map((hotel) => tourHotelsFactoryOut(hotel))
          : undefined,
    };
  }
};

export const tourHotelFactoryIn = (model: TourHotelResponse): TourHotelInfo => {
  return {
    id: model.id,
    '@id': model['@id'],
    name: model.name,
    distance: model.distance,
    address: model.address,
    sale_percent: Number(model.sale_percent) === 0 ? '0' : Number(model.sale_percent)?.toString(),
    sale: Number(model.sale) === 0 ? '0' : Number(model.sale)?.toString(),
    agents_sale_percent: model.agents_sale_percent === null ? 0 : Number(model.agents_sale_percent),
    agents_sale: model.agents_sale === null ? 0 : Number(model.agents_sale),
    tour_hotel_placements:
      model.tour_hotel_placements && Object.values(model.tour_hotel_placements).length
        ? Object.values(model.tour_hotel_placements).map((hotel) => tourHotelsFactoryIn(hotel))
        : [],
  };
};

//конвертация списка поездок - полученный с сервера -> вывод в интерфейс
export const tourDatesFactoryIn = (model: TripResponse): TripInfo => {
  let status = '';
  switch (model.status) {
    case TourStatus.ACTIVE:
      status = TypeTour.ACTIVE_TOUR;
      break;
    case TourStatus.ARCHIVE:
      status = TypeTour.CLOSE_TOUR;
      break;
    default:
      break;
  }

  return {
    id: model.id,
    '@id': model['@id'],
    dateStart: new Date(model.start),
    dateEnd: new Date(model.ending),
    priceOneDay: model.base_price === null ? 0 : Number(model.base_price),
    freePlaces: model.vacant_places_count === null ? 0 : Number(model.vacant_places_count),
    discount_percent: model.sale_percent === null ? 0 : Number(model.sale_percent),
    discount: model.sale === null ? 0 : Number(model.sale),
    agents_sale_percent: model.agents_sale_percent === null ? 0 : Number(model.agents_sale_percent),
    agents_sale: model.agents_sale === null ? 0 : Number(model.agents_sale),
    status: status,
    hotels: model.placements ? Object.values(model.placements).map((hotel) => tourHotelsFactoryIn(hotel)) : [],
    tour_additional_nights: model.tour_additional_nights
      ? Object.values(model.tour_additional_nights).map((hotel) => tourHotelsFactoryIn(hotel))
      : [],
  };
};

//конвертация тура - полученный с сервера -> вывод в интерфейс для редактирования
export const tourFactoryIn = (model: TourResponse & TourParams): TourProps => {
  return {
    id: model.id,
    name: model.name,
    dateCreate: new Date(model.created_at as string),
    template: getTemplateToUI(model.template),
    is_visible: model.is_visible,
    descriptionInfo: {
      meta_tags: {
        title: model.meta_title ? model.meta_title : model.meta_tags.title,
        description: model.meta_description ? model.meta_description : model.meta_tags.description,
      },
      partners_site: model.partners_site,
      filter_price: model.filter_price,
      booking_without_prepay: model.booking_without_prepay,
      currency: getCurrencyToUI(model.currency),
      commission: model.commission,
      duration: model.duration,
      document_template_path: model.document_template_path,
      tourOperator:
        model.tour_operator_contact != null
          ? {
              id: model.tour_operator_contact.id,
              name: model.tour_operator_contact.full_name,
            }
          : { name: 'Акварель' },
      discount: model.sale === null ? 0 : parseInt(model.sale),
      discount_percent: model.sale_percent === null ? 0 : parseInt(model.sale_percent),
      shortDescription: model.short_description,
      fullDescription: model.description,
      tourProgram: model.tour_days ? model.tour_days.map((day) => tourProgramFactoryIn(day)) : [],
      priceIncludes: model.services,
      priceNoIncludes: model.not_in_price,
      additionalServices: model.services_array
        ? model.services_array.filter((i) => i).map((service) => tourServicesFactoryIn(service))
        : [],
      additionalDiscounts: model.sales_array
        ? model.sales_array.map((discount) => tourDiscountsFactoryIn(discount))
        : [],
      price: model.price === null ? 0 : parseInt(model.price),
      addInfo: model.additional_info,
      system_name: model.system_name,
      monday: model.monday,
      monday_title: model.monday_title,
      tuesday: model.tuesday,
      tuesday_title: model.tuesday_title,
      wednesday: model.wednesday,
      wednesday_title: model.wednesday_title,
      thursday: model.thursday,
      thursday_title: model.thursday_title,
      friday: model.friday,
      friday_title: model.friday_title,
      saturday: model.saturday,
      saturday_title: model.saturday_title,
      sunday: model.sunday,
      sunday_title: model.sunday_title,
      eightday: model.eightday,
      eightday_title: model.eightday_title,
      tour_group_placements: model.tour_group_placements,
      tour_group_title: model.tour_group_title,
      settings: {
        photoOrders: model.settings ? model.settings.photoOrders : [],
        tourGroup: Array.isArray(model.settings)
          ? (model.settings as TableDescColumn[])
          : model.settings && model.settings.tourGroup && model.settings.tourGroup.length > 0
          ? model.settings.tourGroup
          : Array.from({ length: 7 }, () => {
              return { name: '' };
            }),
      },
      priority: model.priority,
    },
    tourParameters: {
      tourManager: model.managers ? model.managers.map((manager) => tourManagersFactoryIn(manager)) : [],
      guide: model.guides,
      country: model.countries,
      city: model.cities,
      transport: model.transports,
      tourType: model.labels,
      travelTime: model.tour_periods,
      direction: model.tour_directions,
      tags: model.tour_tags,
      main_tag: model.main_tag && model.main_tag.name,
    },
    tripsInfo: model.tour_dates ? model.tour_dates.map((trip) => tourDatesFactoryIn(trip as TripResponse)) : null,
    tourHotels: model.tour_hotels
      ? model.tour_hotels.map((hotel) => tourHotelFactoryIn(hotel as TourHotelResponse))
      : undefined,
    photo_album: model.photo_album,
    photos: model.photos
      ? model.photos.map((photo) => {
          return {
            id: photo.id,
            title: photo.title,
            base_name: photo.base_name,
            name: photo.name,
            thumbnail_name: photo.thumbnail_name,
            alt: photo.alt,
            photo_tags: photo.photo_tags ? photo.photo_tags.map((el) => el.name) : [],
            is_license: photo.is_license,
            used_count: photo.used_count,
            albums_count: photo.albums_count,
            used_tours: photo.used_tours,
          };
        })
      : [],
  };
};
